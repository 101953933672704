import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import Heading from './heading'

const Hero = ({
  style,
  title,
  subtitle,
  description,
  titleHeadingType,
  descriptionHeadingType,
  extended,
  noWrap,
}) => {
  return (
    <div
      css={[
        global`layout.container`,
        global`layout.grid`,
        tw`w-full h-full py-20 bg-transparent lg:(py-32)`,
        extended && tw`-mt-6 pt-34 pb-36 lg:(pt-46 pb-48)`,
        style,
      ]}
    >
      <div
        css={
          noWrap
            ? tw`col-span-4 md:col-span-8 lg:col-span-12 xl:col-span-14`
            : tw`col-span-4 md:col-span-8 lg:col-span-8 xl:col-span-9`
        }
      >
        {subtitle && (
          <Heading
            content={subtitle}
            headingType={descriptionHeadingType}
            style={tw`pb-12 font-normal text-opacity-77`}
          />
        )}
        <Heading
          content={title}
          headingType={titleHeadingType}
          style={
            noWrap
              ? tw`font-medium xs:text-4xl md:text-5xl lg:w-full`
              : tw`w-3/4 font-medium lg:w-full`
          }
        />
        {description && (
          <Heading
            content={description}
            headingType={descriptionHeadingType}
            style={tw`pt-5 font-normal text-primary-500 text-opacity-77 lg:pt-6`}
          />
        )}
      </div>
    </div>
  )
}
Hero.defaultProps = {
  titleHeadingType: 'h2',
  descriptionHeadingType: 'h5',
}
Hero.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }).isRequired,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }).isRequired,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.exact({
      childMarkdownRemark: PropTypes.exact({ html: PropTypes.string }),
    }),
  ]),
  titleHeadingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  descriptionHeadingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  extended: PropTypes.bool,
  noWrap: PropTypes.bool,
}
export default Hero
